var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('div',[_c('v-bottom-navigation',{staticClass:"boutton-barre",attrs:{"app":"","value":_vm.value,"color":"teal","dark":"","grow":""},model:{value:(_vm.choixNavBarre),callback:function ($$v) {_vm.choixNavBarre=$$v},expression:"choixNavBarre"}},[_c('v-btn',{attrs:{"id":"no-background-hover","color":"#2E2E2E","value":"moi","ripple":false},on:{"click":function($event){return _vm.isConnected('moi')}}},[_c('div',{staticClass:"boutton-classique-container"},[_c('div',{class:_vm.choixNavBarre === 'moi'
                ? 'boutton-explorer-content active'
                : 'boutton-explorer-content'},[_c('v-icon',{staticClass:"icone-barre-navigation"},[_vm._v("mdi-account-circle-outline ")]),_c('span',{staticClass:"intitule-barre"},[_vm._v("Profil")])],1)])]),_c('v-btn',{staticClass:"boutton-explorer",attrs:{"id":"no-background-hover","color":"#2E2E2E","value":"rechercher","to":"/","ripple":false}},[_c('div',{staticClass:"boutton-explorer-container"},[_c('div',{class:_vm.choixNavBarre === 'rechercher'
                ? 'boutton-explorer-content active'
                : 'boutton-explorer-content'},[_c('svg',{staticStyle:{"fill":"none","height":"22px","width":"22px","stroke":"currentcolor","stroke-width":"4","overflow":"visible"},attrs:{"viewBox":"0 0 32 32","xmlns":"http://www.w3.org/2000/svg","aria-hidden":"true","role":"presentation","focusable":"false"}},[_c('g',{attrs:{"fill":"none"}},[_c('path',{attrs:{"d":"m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"}})])]),_c('span',{staticClass:"intitule-barre texte-boutton-explorer"},[_vm._v("Explorer")])])])]),_c('v-btn',{attrs:{"id":"no-background-hover","color":"#2E2E2E","value":"ajout","to":"/ajout","ripple":false},on:{"click":function($event){return _vm.isConnected('ajout')}}},[_c('div',{staticClass:"boutton-classique-container"},[_c('div',{class:_vm.choixNavBarre === 'ajout'
                ? 'boutton-explorer-content active'
                : 'boutton-explorer-content'},[_c('v-icon',{staticClass:"icone-barre-navigation"},[_vm._v("mdi-plus-circle-outline")]),_c('span',{staticClass:"intitule-barre"},[_vm._v("Ajouter")])],1)])]),_c('v-btn',{attrs:{"id":"no-background-hover","color":"#2E2E2E","value":"info","to":"infos","ripple":false}},[_c('div',{staticClass:"boutton-classique-container"},[_c('div',{class:_vm.choixNavBarre === 'info'
                ? 'boutton-explorer-content active'
                : 'boutton-explorer-content'},[_c('v-icon',{staticClass:"icone-barre-navigation"},[_vm._v("mdi-information-outline")]),_c('span',{staticClass:"intitule-barre"},[_vm._v("Infos")])],1)])])],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogConnexion),callback:function ($$v) {_vm.dialogConnexion=$$v},expression:"dialogConnexion"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h15"},[_vm._v(" Connexion ")]),_c('v-card-subtitle',[_vm._v("Pour accéder à cette page, veuillez vous connecter")]),_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('div',[_c('button',{staticClass:"social-button",on:{"click":_vm.googleSignIn}},[_c('img',{staticClass:"logoGoogle",attrs:{"src":require("@/assets/google.png"),"alt":"Logo Google"}})]),_c('br'),_c('p',[_vm._v("Se connecter avec Google")])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"no-background-hover","color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogConnexion = false}}},[_vm._v(" Annuler ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }