<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <router-view> </router-view>
      </v-container>
    </v-main>

    <div>
      <v-bottom-navigation
        app
        :value="value"
        v-model="choixNavBarre"
        color="teal"
        dark
        grow
        class="boutton-barre"
      >
        <v-btn
          id="no-background-hover"
          color="#2E2E2E"
          value="moi"
          @click="isConnected('moi')"
          :ripple="false"
        >
          <div class="boutton-classique-container">
            <div
              :class="
                choixNavBarre === 'moi'
                  ? 'boutton-explorer-content active'
                  : 'boutton-explorer-content'
              "
            >
              <v-icon class="icone-barre-navigation"
                >mdi-account-circle-outline
              </v-icon>
              <span class="intitule-barre">Profil</span>
            </div>
          </div>
        </v-btn>
        <v-btn
          id="no-background-hover"
          color="#2E2E2E"
          value="rechercher"
          to="/"
          class="boutton-explorer"
          :ripple="false"
        >
          <div class="boutton-explorer-container">
            <div
              :class="
                choixNavBarre === 'rechercher'
                  ? 'boutton-explorer-content active'
                  : 'boutton-explorer-content'
              "
            >
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style="
                  fill: none;
                  height: 22px;
                  width: 22px;
                  stroke: currentcolor;
                  stroke-width: 4;
                  overflow: visible;
                "
              >
                <g fill="none">
                  <path
                    d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"
                  ></path>
                </g>
              </svg>
              <span class="intitule-barre texte-boutton-explorer"
                >Explorer</span
              >
            </div>
          </div>
          <!-- <v-icon class="icone-barre-navigation">mdi-magnify</v-icon> -->
        </v-btn>

        <v-btn
          id="no-background-hover"
          color="#2E2E2E"
          value="ajout"
          to="/ajout"
          @click="isConnected('ajout')"
          :ripple="false"
        >
          <div class="boutton-classique-container">
            <div
              :class="
                choixNavBarre === 'ajout'
                  ? 'boutton-explorer-content active'
                  : 'boutton-explorer-content'
              "
            >
              <v-icon class="icone-barre-navigation"
                >mdi-plus-circle-outline</v-icon
              >
              <span class="intitule-barre">Ajouter</span>
            </div>
          </div>
        </v-btn>

        <v-btn
          id="no-background-hover"
          color="#2E2E2E"
          value="info"
          to="infos"
          :ripple="false"
        >
          <div class="boutton-classique-container">
            <div
              :class="
                choixNavBarre === 'info'
                  ? 'boutton-explorer-content active'
                  : 'boutton-explorer-content'
              "
            >
              <v-icon class="icone-barre-navigation"
                >mdi-information-outline</v-icon
              >
              <span class="intitule-barre">Infos</span>
            </div>
          </div>
        </v-btn>
      </v-bottom-navigation>
    </div>

    <v-dialog v-model="dialogConnexion" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h15"> Connexion </v-card-title>
        <v-card-subtitle
          >Pour accéder à cette page, veuillez vous connecter</v-card-subtitle
        >
        <v-card-text class="d-flex justify-center">
          <div>
            <button class="social-button" @click="googleSignIn">
              <img
                class="logoGoogle"
                src="@/assets/google.png"
                alt="Logo Google"
              />
            </button>
            <br />
            <p>Se connecter avec Google</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="no-background-hover"
            color="green darken-1"
            text
            @click="dialogConnexion = false"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import {
  getAuth,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

export default {
  name: "App",
  components: {},

  data: () => ({
    value: "rechercher",
    dialogConnexion: false,
    routerToGo: "",
    tabs: null,
    choixNavBarre: "rechercher",
  }),
  methods: {
    googleSignIn() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          this.dialogConnexion = false;
          axios
            .get(process.env.VUE_APP_URL + "/user/get", {
              params: {
                uid: result.user.uid,
              },
            })
            .then((response) => {
              console.log(response.data);
              this.$store.state.user = response.data;
              this.$store.state.connected = true;
            })
            .catch(() => {
              this.registerUser(result.user);
            });

          this.$router.push({ name: this.routerToGo });
        })
        .catch((error) => {
          this.dialogConnexion = false;
          console.log(error);
        });
    },
    registerUser(user) {
      let myUser = {
        nom: user.displayName,
        email: user.email,
        uid: user.uid,
      };
      let config = {
        method: "post",
        url: process.env.VUE_APP_URL + "/user/creation",
        headers: {
          "Content-Type": "application/json",
        },
        data: myUser,
      };

      axios(config)
        .then(() => {
          this.$store.state.user = myUser;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    isConnected(router) {
      this.routerToGo = router;
      if (!this.$store.state.connected) {
        this.$router.push({ name: "recherche" });
        this.dialogConnexion = true;
      } else {
        this.$router.push({ name: router });
      }
    },
    getVille() {
      axios
        .get(process.env.VUE_APP_URL + "/ville/")
        .then((response) => {
          this.$store.state.listeVille = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getCategorie() {
      axios
        .get(process.env.VUE_APP_URL + "/categorie/")
        .then((response) => {
          this.$store.state.listeCategorie = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    signOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.$store.state.connected = false;
          this.$router.push({ name: "recherche" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    color() {
      switch (this.value) {
        case 0:
          return "blue-grey";
        case 1:
          return "teal";
        case 2:
          return "brown";
        case 3:
          return "indigo";
        default:
          return "red";
      }
    },
  },
  created() {
    this.$router.push({ name: "recherche" });
    this.getVille();
    this.getCategorie();
    this.$store.commit("isConnected");
  },
};
</script>
<style scoped lang="scss">
.v-sheet.v-list {
  background-color: #2e2e2e !important;
}
.logoGoogle {
  width: 5em;
  background: white;
  padding: 1em;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 2);
  outline: 0;
  border: 0;
}
.logoGoogle:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.social-button {
  text-align: center;
  margin: auto;
  display: flex;
}
.intitule-barre {
  font-size: 10px;
  padding-top: 0.4em;
  font-weight: 500;
  font-family: sans-serif;
}
.icone-barre-navigation {
  font-size: 2.4em !important;
}
.icone-barre-navigation {
  font-size: 2.4em !important;
}

.boutton-explorer {
  padding-top: 0.15em !important;
  padding-bottom: 5em;
}
.boutton-explorer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boutton-explorer-container .active {
  background-color: #2e2e2e;
  padding-top: 1em;
  position: relative;
  padding-bottom: 3.3em;
  transition: all 0.2s;
  top: -0.4em;
  width: 6em;
  border-radius: 3em 3em 0em 0em;
  .intitule-barre {
    padding-top: 1em;
    font-size: 1em;
  }
}

.boutton-classique-container .active {
  background-color: #2e2e2e;
  padding-top: 1em;
  position: relative;
  padding-bottom: 2.9em;
  transition: all 0.2s;
  top: -0.4em;
  width: 6em;
  border-radius: 3em 3em 0em 0em;
  .intitule-barre {
    padding-top: 1em;
    font-size: 1em;
  }
}

.boutton-classique-container .active::after {
  content: "";
  height: 1.3rem;
  width: 1.3em;
  border-radius: 0em 0em 0em 2em;
  border-bottom: 9px solid #2e2e2e;
  right: -11px;
  top: 1.35em;
  position: absolute;
}

.boutton-classique-container .active::before {
  content: "";
  height: 1.3rem;
  width: 1.3em;
  border-radius: 0em 0em 0em 2em;
  border-bottom: 9px solid #2e2e2e;
  right: 66px;
  top: 1.35em;
  transform: scaleX(-1);
  position: absolute;
}

.boutton-explorer-container .active::after {
  content: "";
  height: 1.3rem;
  width: 1.3em;
  border-radius: 0em 0em 0em 2em;
  border-bottom: 9px solid #2e2e2e;
  right: -11px;
  transition: all 1s;
  top: 1.19em;
  position: absolute;
}
.boutton-explorer-container .active::before {
  content: "";
  height: 1.3rem;
  width: 1.3em;
  border-radius: 0em 0em 0em 2em;
  border-bottom: 9px solid #2e2e2e;
  right: 66px;
  transition: all 2s;
  top: 1.19em;
  transform: scaleX(-1);
  position: absolute;
}
</style>
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
body {
  font-family: "Roboto", sans-serif !important;
}
</style>
<style lang="scss" scoped>
#no-background-hover::before {
  background-color: transparent !important;
}
</style>