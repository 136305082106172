import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'recherche', component: () => import(/* webpackChunkName: "recherche" */'../components/recherche/Recherche.vue') },
  { path: '/ajout', name: 'ajout', component: () => import(/* webpackChunkName: "ajout" */'../components/ajout/AjoutItem.vue') },
  { path: '/moi', name: 'moi', component: () => import(/* webpackChunkName: "moi" */'../components/mesActivites/MyActivities.vue') },
  { path: '/infos', name: 'infos', component: () => import(/* webpackChunkName: "infos" */'../components/informations/InformationApp.vue') },
  { path: '/login', name: 'login', component: () => import(/* webpackChunkName: "login" */'../components/user/LoginUser.vue') },
]

const router = new VueRouter({
  mode:'abstract',
  routes
})

export default router
