import Vue from 'vue'
import Vuex from 'vuex'
import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    listeVille: [],
    listeCategorie: [],
    connected: false,
    userId: "",
    // user: { // Temporaire pour le dev offline
    //   nom: '',
    //   uid: '',
    //   email: ''
    // },
    user: {},
    datesChoisies: [],
    villeSelectionne: {
      "nom": "Nantes",
      "latitude": 47.2186371,
      "longitude": -1.5541362
    },
    coordonneeVueEcran: []
  },
  getters: {
  },
  mutations: {
    isConnected() {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.state.connected = true
          this.state.user = {
            nom: user.displayName,
            uid: user.uid,
            email: user.email
          }
        }
      });
    },
    // isConnected() {
    //   this.state.connected = true
    // },
  },
  actions: {
  },
  modules: {
  }
})
