import Vue from 'vue'
import App from './App.vue'
import 'leaflet/dist/leaflet.css';
// import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify';

import 'vuetify/dist/vuetify.min.css';
import fr from 'vuetify/es5/locale/fr';
import router from './router/index.js'
import store from './store'
import * as firebase from "firebase/app";
import './registerServiceWorker'


Vue.config.productionTip = false
Vue.use(Vuetify);

const vuetify = new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr',
  },
});
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBpx8wHxLiUUECyVK5PPzzNbycLkFpQ8dA",
  authDomain: "citylife-ab575.firebaseapp.com",
  projectId: "citylife-ab575",
  storageBucket: "citylife-ab575.appspot.com",
  messagingSenderId: "119314272301",
  appId: "1:119314272301:web:ec8d01c5ffc398f21a28a4",
  measurementId: "G-8TTDDQYZFE"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
